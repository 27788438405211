@import "palettes";
@import "theme";
@import "mixins";

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}


.truncate:not(:focus) {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

[data-hide="true"] {
  display: none !important;
}
